'use client'

import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { Md5 } from 'ts-md5'

export function initGTM(trackingId?: string) {
  useEffect(() => {
    if (trackingId) {
      // Note: this will fire twice in DEV mode due to react 18 rendering behaviour
      // see here: https://github.com/vercel/next.js/issues/35822
      window.dataLayer = window.dataLayer || []
      TagManager.initialize({ gtmId: trackingId })
    }
  }, [])
}

export function gtmPush(gtmEvent: any) {
  /* @ts-ignore */
  let dataLayerLogger = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    push: (args: Record<string, any>) => ({}),
  }

  /* eslint-disable valid-typeof */
  if (typeof window !== undefined && window.dataLayer) {
    dataLayerLogger = window.dataLayer
  }

  dataLayerLogger.push(gtmEvent)
}

export function gtmCategory(item: string | undefined) {
  const categorieProp = {}
  const categorie = item

  categorie
    ?.split('/')
    .filter((n) => n)
    .map((cat, index) => {
      index === 0
        ? (categorieProp[`item_category`] = cat)
        : (categorieProp[`item_category${index + 1}`] = cat)
    })
    .slice(0, 4)

  return categorieProp
}

export function md5Encrypt(value: (string | number) | undefined | null) {
  return value ? Md5.hashStr(value?.toString()) : ''
}
