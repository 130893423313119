import sanitizeHtml from 'sanitize-html'

type KeyTranslationOptions = {
  variables?: Record<string, number | string>
}

// strip all tags
const stripOptions = {
  allowedTags: [],
  allowedAttributes: [],
}

// default sanitize options
const defaultOptions = {
  allowedTags: [
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: ['src'],
  },
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  allowedSchemesByTag: {},
}

const findTranslation =
  (dictionary: Record<string, string>) =>
  (key: string, options: KeyTranslationOptions = {}, allowTags: boolean = false) => {
    const tagRegex = /<[^]*?>/gm
    const varRegex = /%[1-9]/gm

    let translation = dictionary[key]

    const sanitizeStr = (str: string) => {
      const sanitizeOptions = allowTags ? defaultOptions : stripOptions
      const clean = sanitizeHtml(str, sanitizeOptions)

      return clean
    }

    if (!translation) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`key "${key}" has no translation`)
      }

      translation = key
    }

    if (options.variables && Object.entries(options.variables).length) {
      translation.match(varRegex)?.forEach((hit, index) => {
        translation = translation!.replace(String(hit), String(options?.variables?.[index]))
      })
    }

    return translation.match(tagRegex)
      ? ((<span dangerouslySetInnerHTML={{ __html: sanitizeStr(translation) }}></span>) as any)
      : translation
  }

export default findTranslation
