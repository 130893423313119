export const buildFullUrlMedia = (partialUrl: string) => {
  const publicSiteUrl = process.env.NEXT_PUBLIC_CLOUDFRONT_URL
  if (!publicSiteUrl) {
    return partialUrl
  }
  if (partialUrl.includes(publicSiteUrl)) {
    return partialUrl
  }
  // avoid double //, because it will process a redirection 308 and add a new hit
  if (publicSiteUrl.endsWith('/') && partialUrl.startsWith('/')) {
    return `${publicSiteUrl}${partialUrl.replace('/', '')}`
  }
  return `${publicSiteUrl}${partialUrl}`
}

export const handleError = (error: unknown, shouldThrowError: boolean = true, prefix?: string) => {
  let formattedError

  if (error instanceof Error) {
    formattedError = error.message
  } else {
    formattedError = String(error)
  }

  /* eslint-disable no-console */
  console.error(prefix ?? '', formattedError)

  if (shouldThrowError) {
    throw new Error(formattedError)
  }
}
